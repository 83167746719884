import Vue from 'vue'
import VueRouter from 'vue-router'
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
import { useloggedUserInfo } from "../stores/UserInfo";
import Unauthorized from '../views/auth/Unauthorized.vue'
import { contourDensity } from 'd3';
Vue.use(VueRouter)
const routes = [
    {
        path: "/HelpAndGuide",
        name: "HelpAndGuide",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HelpAndGuide/HelpAndGuide.vue"),
        meta: {
            title: 'HelpAndGuide',
            breadcrumb: [
                { name: "Help And Guide" },

            ],
        } // <- I would to use this one
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    }, 
    {
        path: "/HelpArticles",
        name: "HelpArticles",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HelpAndGuide/HelpArticles.vue"),
        meta: {
            title: 'HelpArticles',
            breadcrumb: [
                { name: "Help And Guide", link: "/HelpAndGuide" },
                { name: "Help Article" },
            ],
        } // <- I would to use this one
    },
    {
        path: "/UserManual",
        name: "UserManual",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HelpAndGuide/UserManual.vue"),
        meta: {
            title: 'UserManual',
            breadcrumb: [
                { name: "Help And Guide", link: "/HelpAndGuide" },
                { name: "User Manual" },
            ],
        } // <- I would to use this one
    },
    {
        path: "/HelpArticlesPerModule",
        name: "HelpArticlesPerModule",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HelpAndGuide/HelpArticlesPerModule.vue"),
        meta: {
            title: 'HelpArticlesPerModule',

            breadcrumb: [
                { name: "Help Article", link: "/HelpArticles" },
                { name: "Help Article Per Module" },
            ],
        } // <- I would to use this one
    },
    {
        path: "/VideoTutorials",
        name: "VideoTutorials",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HelpAndGuide/VideoTutorials.vue"),
        meta: {
            title: 'VideoTutorials',
            breadcrumb: [
                { name: "Help And Guide", link: "/HelpAndGuide" },
                { name: "Video Tutorial" },
            ],
        } // <- I would to use this one
    },
    {
        path: "/Report/Index",
        name: "CustomReports",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/Reporting/List.vue"),
        meta: { 
            title: 'Custom Reports',
            controllerName:'Report',
            actionName: 'Index' 
        } // <- I would to use this one
    },
    // {
    //     path: "/Report/Index",
    //     name: "CustomReportsfullmenu",
    //     // route level code-splitting
    //     // this generates a separate chunk (About.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import("@/views/Reporting/List.vue"),
    //     meta: { title: 'Custom Reports' } // <- I would to use this one
    // },
    {
        path: '/Report/viewcustomreport/:ReportID',
        name: "viewcustomreport",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/Reporting/Add_Report/ViewCustomReport.vue"),
        meta: {
            title: 'View Custom Report',
            breadcrumb: [
                { name: "Custom Report", link: "/Report/Index" },
                { name: "View Report" }
            ],
            controllerName:'Report',
            actionName:'View'
        } // <- I would to use this one
    },
    {
        path: '/Report/Add/:ReportID',
        name: "AddDataReport",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/Reporting/Add_Report/Datareport.vue"),
        meta: {
            title: 'Data Report',
            breadcrumb: [
                { name: "Custom Report", link: "/Report/Index" },
                { name: "Data Report" },
            ],
            controllerName:'Report',
            actionName:'Add'
        } // <- I would to use this one
    },
    {
        path: '/Report/Edit/:ReportID',
        name: "EditDataReport",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/Reporting/Add_Report/Datareport.vue"),
        meta: {
            title: 'Data Report',
            breadcrumb: [
                { name: "Custom Report", link: "/Report/Index" },
                { name: "Data Report" },
            ],
            controllerName:'Report',
            actionName:'Update'
        } // <- I would to use this one
    },
    {
        path: "/SetupGuides",
        name: "SetupGuides",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HelpAndGuide/SetupGuides.vue"),
        meta: {
            title: 'SetupGuides',
            breadcrumb: [
                { name: "Help And Guide", link: "/HelpAndGuide" },
                { name: "Setup Guides" },
            ],
        } // <- I would to use this one
    },
    {
        path: "/Report/EmailScheduleListing",
        name: "EmailScheduleListing",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/Reporting/EmailScheduleListing.vue"),
        meta: {
            title: 'Email Schedule Listing',
            breadcrumb: [
                { name: "Custom Reports", link: "/Report/Index" },
                { name: "Scheduled Reports" },
            ],
            controllerName:'Report',
            actionName:'EmailScheduleListing'
        } // <- I would to use this one
    },
    {
        path: "/Report/ScheduledHistory",
        name: "ScheduledHistory",
        component: () => import("@/views/Reporting/ReportScheduledHistory/HistoryListing.vue"),
        meta: {
            title: 'Scheduled Report History',
            breadcrumb: [
                { name: "Scheduled Reports", link: "/Report/EmailScheduleListing" },
                { name: "Scheduled Report History" },
            ],
            controllerName:'Report',
            actionName:'ScheduledHistory'
        }
    },
    {
        path: "/Report/ReportListing",
        name: "ReportListing",
        component: () => import("@/views/Reporting/Add_Report/ReportListing.vue"),
        meta: {
            title: 'Report Listing',
            breadcrumb: [
                { name: "Custom Report", link: "/Report/Index" },
                { name: "View Report" }
            ],
            controllerName:'Report',
            actionName: 'ReportListing'
        }
    },
    
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if(to.meta.hasOwnProperty('checkPrivilege'))
            {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if(isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else{
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            authService.authenticate(to.path).then(() => {
                console.log('authenticating a protected url:' + to.path);
                next();
            });
        }
    }
});
export default router
